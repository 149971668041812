import { DataFieldTextArea, Unifree } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'

interface ActivityValueProps {
  uuid?: string
  label?: string
  value: string | { id: string; des: string } | boolean | number
  contentType?: string
  required?: boolean
}

const multiselections = [
  'autocomplete_multiselection',
  'explicit_multiselection_objectclass'
]

function isMultiselection(contentType: string) {
  return multiselections.includes(contentType)
}

function getCommonValue(value: ActivityValueProps['value']) {
  switch (typeof value) {
    case 'string':
      return value
    case 'number':
      return value.toString()
    case 'boolean':
      return ''
    default:
      return value?.des ?? ''
  }
}

const ActivityValue = ({
  uuid,
  label,
  value,
  contentType,
  required
}: ActivityValueProps) => {
  const { t } = useTranslation()

  const displayedLabel =
    contentType === 'agreement_term'
      ? t('activity-input-agreement-term.status')
      : label

  // todo: contentType === 'date', usando formato da lingua atual

  if (contentType === 'agreement_term' || typeof value === 'boolean') {
    const agreementTermValue = value
      ? t('activity-input-agreement-term.accept')
      : t('activity-input-agreement-term.declined')

    return (
      <Unifree
        id={uuid}
        label={displayedLabel}
        value={agreementTermValue}
        heightClass='large'
        informational
        required={required}
      />
    )
  }

  const commonValue = getCommonValue(value)

  if (contentType && isMultiselection(contentType)) {
    const valueWithLinebreaks = commonValue.split(', ').join('\n')
    return (
      <DataFieldTextArea
        id={uuid}
        label={displayedLabel}
        value={valueWithLinebreaks}
        spacingClass='large'
        informational
        required={required}
      />
    )
  }

  return (
    <Unifree
      id={uuid}
      label={displayedLabel}
      value={commonValue}
      heightClass='large'
      informational
      required={required}
    />
  )
}

export default ActivityValue

import { InformationCard, Button } from '@yes.technology/react-toolkit'
import { useReducer } from 'react'
import logo from '../../../asset/img/logo-yes-dot.png'
import { InformationCard as TInformationCard } from 'types/shared'

type GetCurrentCardParams = {
  cards: TInformationCard[]
  currentStep: number
  isInteractionFinished: boolean
}

const getCurrentCard = ({
  cards,
  currentStep,
  isInteractionFinished
}: GetCurrentCardParams) => {
  if (isInteractionFinished) {
    return cards.find((card) => card.display_on === 'interaction-finished')
  }

  if (currentStep === 1) {
    return cards.find((card) => card.display_on === 'interaction-started')
  }
}

type InteractionInformationProps = {
  cards: TInformationCard[]
  currentStep: number
  isInteractionFinished: boolean
}

export default function InteractionInformation({
  cards = [],
  currentStep,
  isInteractionFinished
}: InteractionInformationProps) {
  const [isOpen, toggleOpen] = useReducer((prevIsOpen) => !prevIsOpen, true)
  const currentCard = getCurrentCard({
    cards,
    currentStep,
    isInteractionFinished
  })

  if (!currentCard) {
    return null
  }

  return (
    <InformationCard
      title=''
      spacingClass='medium'
      close={toggleOpen}
      {...{ isOpen }}
    >
      <div className='d-flex flex-column gap-4 align-items-center mt-1 p-3'>
        <img
          src={logo}
          alt='Yes.'
          style={{ position: 'absolute', top: '24px' }}
        />
        {currentCard.image_url && (
          <img
            src={currentCard.image_url}
            alt=''
            width='280'
            height='280'
            className='rounded-circle'
            style={{ border: '2px solid #DDE5ED' }}
          />
        )}
        <h2
          style={{ fontSize: '32px', lineHeight: '48px', fontWeight: 700 }}
          className='mb-0'
        >
          {currentCard.des}
        </h2>
        <span style={{ fontSize: '18px', lineHeight: '24px' }}>
          {currentCard.complement}
        </span>
        {currentCard.action_button && (
          <a href={currentCard.action_button.url} className='col-12'>
            <Button>{currentCard.action_button.des}</Button>
          </a>
        )}
      </div>
    </InformationCard>
  )
}

import { StandardUniselection } from '@yes.technology/react-toolkit'
import useFetchSuggestions from '../../../hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'
import { FilteractionParams } from 'utils/apiTypes'

export type ActivityStandardUniselectionProps = {
  id: string
  label: string
  value: ActivityModel['value']
  filteraction?: string
  filteractionParams?: FilteractionParams[]
  onChange: ActivityInputProps['handleChange']
  options?: { uuid: string; des: string }[]
} & Pick<
  React.ComponentProps<typeof StandardUniselection>,
  'id' | 'label' | 'required' | 'statusClass' | 'placeholder'
>

const ActivityStandardUniselection = ({
  id,
  label,
  value,
  filteraction,
  filteractionParams,
  options = [],
  onChange,
  required,
  statusClass,
  placeholder
}: ActivityStandardUniselectionProps) => {
  const formattedValue: { id: string; value: string } = {
    id: typeof value === 'object' ? value?.id : '',
    value: typeof value === 'object' ? value?.des : ''
  }

  const selectedOption = typeof value === 'object' ? formattedValue : undefined

  const { suggestions, fetchSuggestions, isLoading } = useFetchSuggestions({
    id,
    filteraction,
    filteractionParams
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])

  const finalOptions = options.length ? options : suggestions

  const handleChange = ({ id, value }: { id: string; value: string }) => {
    onChange({
      id: id,
      des: value
    })
  }

  const loadingStatusClass = isLoading ? 'processing' : undefined

  return (
    <StandardUniselection
      id={id}
      label={label}
      heightClass='medium'
      options={finalOptions.map((rawOption) => ({
        id: rawOption.uuid,
        value: rawOption.des
      }))}
      selectedOption={selectedOption}
      required={required}
      onSelect={handleChange}
      statusClass={loadingStatusClass || statusClass}
      placeholder={placeholder}
    />
  )
}

export default ActivityStandardUniselection

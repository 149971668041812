import api from '../../utils/api'

// resolveQuery recebe um objeto no padrão YesQueryLanguage,
// padrão inspirado no MongoDB, e resolve recursivamente todos
// os operadores do tipo $filteraction, se existir
//
// Se não existir nenhum operador do tipo $filteraction
// para ser resolvido, simplesmente devolvemos o mesmo
// queryObject
//
// O método aceita um callback onProgressCallback que é chamado
// a cada nova etapa da resolução dos filteractions,
// para permitir que a UI comunique o progresso ao usuário do sistema
//
// O array fetchedFilteractionIds é utilizado para identificar loops infinitos
export async function resolveQuery(
  queryObjectOriginal,
  onProgressCallback,
  fetchedFilteractionIds = [],
  showLoading = false
) {
  const queryObject = { ...queryObjectOriginal }
  // Loop recursivo entre todos os objetos da query
  for (const key of Object.keys(queryObject)) {
    if (key === '$in' || key === '$nin') {
      // Nada a fazer
    } else if (Array.isArray(queryObject[key])) {
      // Se for um array ($and/$or)
      for (const index in queryObject[key]) {
        queryObject[key][index] = await resolveQuery(
          queryObject[key][index],
          onProgressCallback,
          fetchedFilteractionIds,
          showLoading
        )
      }
    } else if (queryObject[key] instanceof Object) {
      // Se for um objeto
      queryObject[key] = await resolveQuery(
        queryObject[key],
        onProgressCallback,
        fetchedFilteractionIds,
        showLoading
      )
    } else {
      // Se chegou aqui é uma primativa (strings, inteiros, etc)
      if (key === '$filteraction') {
        // Se for uma primativa com chave $filteraction, significa que temos
        // um UUID de uma filteraction, que será consultado no backend,
        // e sua query será resolvida
        const filteractionId = queryObject[key]
        if (fetchedFilteractionIds.includes(filteractionId)) {
          queryObject[key] = '__CYCLIC_DEPENDENCY_DETECTED__'
          publishProgress(
            onProgressCallback,
            'Cyclic dependency detected on Filteraction ID ' + filteractionId
          )
        } else {
          fetchedFilteractionIds.push(filteractionId)
          publishProgress(
            onProgressCallback,
            'Fetching Filteraction ID ' + filteractionId
          )
          const filteraction = await fetchFilteraction(
            filteractionId,
            showLoading
          )
          publishProgress(
            onProgressCallback,
            'Finished fetching Filteraction ID ' + filteractionId
          )
          const query = JSON.parse(filteraction.query)
          const filteractionResolvedQuery = await resolveQuery(
            query,
            onProgressCallback,
            fetchedFilteractionIds,
            showLoading
          )
          delete queryObject[key]
          const objectclassId = filteraction.filter_action_objectclass[0].uuid
          publishProgress(
            onProgressCallback,
            'Executing query on Objectclass ID ' + objectclassId
          )
          const objectsResponse = await fetchUUIDDes(
            objectclassId,
            filteractionResolvedQuery,
            onProgressCallback,
            showLoading
          )
          publishProgress(
            onProgressCallback,
            'Finished executing query on Objectclass ID ' + objectclassId
          )
          queryObject.$in = objectIds(objectsResponse?.data?.objects || [])
        }
      }
    }
  }
  return { ...queryObject }
}

// fetchFilteraction obtém a query de uma Filteraction a partir de seu UUID
export async function fetchFilteraction(
  filteractionId,
  showLoading,
  throwError
) {
  // A variável window.idObjectclassFilteraction é alimentada pelo config.js
  const response = await executeQuery(
    window.idObjectclassFilteraction,
    { 'uuid.des': { $eq: filteractionId } },
    undefined,
    false,
    true,
    { query: 1, filter_action_objectclass: 1 },
    showLoading,
    throwError
  )

  return response?.data?.objects ? response?.data?.objects[0] : {}
}

// fetchUUIDDes obtém UUID e DES usando a query da Filteraction
export async function fetchUUIDDes(
  objectclassId,
  query,
  onProgressCallback,
  showLoading = false,
  throwError
) {
  const response = await executeQuery(
    objectclassId,
    query,
    onProgressCallback,
    true,
    true,
    { uuid: 1, des: 1 },
    showLoading,
    throwError
  )
  return response
}

// executeQuery recebe uma objectclassId e uma query, executa
// o fetch no backend, e retorna os objetos encontrados
export async function executeQuery(
  objectclassId,
  query,
  onProgressCallback,
  skipRelations,
  skipMetadata,
  projectFields,
  showLoading = false,
  throwError
) {
  const response = await api.postObjectsFilter(
    {
      query: query,
      objectclass: objectclassId,
      orderby: [],
      pagination: {
        offset: 0,
        limit: 9999999
      },
      visualization: 'classic-list',
      skip_relations: skipRelations,
      skip_metadata: skipMetadata,
      project_fields: projectFields
    },
    showLoading,
    throwError
  )
  return response
}

export function objectIds(objectArray) {
  return objectArray.map((i) => i.id || i.uuid)
}

function publishProgress(callback, message) {
  if (typeof callback === 'function') {
    callback(message)
  }
}

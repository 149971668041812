import { HierarchyCard } from '@yes.technology/react-toolkit'

import Activity from './ActivityInput/Activity'
import Review from './Review'
import { FormEventHandler, useCallback } from 'react'
import {
  Activity as ActivityType,
  InteractionModelItemGroup
} from 'types/shared'
import SelectAndLinkFrame from './SelectAndLinkFrame/SelectAndLinkFrame'

type InteractionStepProps = {
  totalSteps: number
  currentStep: number
  interactionItemGroup?: InteractionModelItemGroup
  handleChangeStep: (step: number) => void
  handleChangeActivity: (activity: ActivityType) => void
}

const InteractionStep = ({
  totalSteps,
  currentStep,
  interactionItemGroup,
  handleChangeStep,
  handleChangeActivity
}: InteractionStepProps) => {
  const changeActivity = useCallback(
    (id: string, newValue: { id: string; des: string } | string) => {
      const activityValue =
        typeof newValue === 'object' ? newValue.id : newValue

      handleChangeActivity({
        uuid_activity_model: id,
        des: activityValue,
        complement: typeof newValue === 'object' ? newValue.des : undefined
      })
    },
    [handleChangeActivity]
  )

  const onSave = () => {
    handleChangeStep(totalSteps + 1)
  }

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()
    handleChangeStep(currentStep + 1)
  }

  return (
    <div style={{ maxWidth: 608 }} className='m-auto mt-3 mb-3'>
      {currentStep < totalSteps && (
        <SelectAndLinkFrame>
          <HierarchyCard>
            {interactionItemGroup && interactionItemGroup.des && (
              <h2>{interactionItemGroup.des}</h2>
            )}

            {interactionItemGroup?.complement && (
              <p>{interactionItemGroup.complement}</p>
            )}

            {interactionItemGroup &&
              Array.isArray(interactionItemGroup.activity_models) && (
                <form onSubmit={onSubmit}>
                  {interactionItemGroup.activity_models.map((activityModel) => (
                    <Activity
                      key={activityModel.uuid}
                      activityModelInput={activityModel}
                      changeActivity={changeActivity}
                      currentStep={currentStep}
                    />
                  ))}
                  {/* https://stackoverflow.com/questions/4196681/form-not-submitting-when-pressing-enter */}
                  {/* ...When there is only a single text field in a form, pressing 'enter' in the text field
               will automatically submit; but if there is more than one (regular (i.e. single-line)
               text input) field, it does nothing, unless there is also some kind of 'submit' button. */}
                  <input type='submit' style={{ display: 'none' }} />
                </form>
              )}
          </HierarchyCard>
        </SelectAndLinkFrame>
      )}

      {currentStep === totalSteps && (
        <Review onSave={onSave} goToStep={handleChangeStep} />
      )}
    </div>
  )
}

export default InteractionStep
